import { getLoggedInSession } from "@app/ACL/useLogin";
import { useTranslation } from "react-i18next";

export const useAvatarInfo = () => {
    const { name, accessLevel, businessName, isSuperAdmin } =
        getLoggedInSession();
    const { t } = useTranslation('navbar');

    const shownAccessLevel = isSuperAdmin ? 'Admin' : accessLevel;

    const displayAccessLevel = shownAccessLevel ?? t('noAccessLevel');
    const userName = name ?? businessName;
    const displayName = name ?? businessName ?? t('noName');
    const letter = !!userName ? userName?.charAt(0) : '?';

    return {
        displayAccessLevel,
        userName,
        displayName,
        letter
    }
}