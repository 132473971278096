import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { parseJson } from '@zeal/zeal-lib';
import { Cookies } from 'react-cookie';
import { TOKEN_COOKIE_NAME } from '@app/ACL/useLogin';

export const KEYS_LOGIN = 'zeal-vndr-usr';
export const KEYS_PERMISSIONS = 'zeal-vndr-permissions';

type MerchantType = 'freemium' | 'premium' | 'cardlink' | 'legacy';

export interface LoginVault {
	id: number;
	keepSignIn: boolean;
	isSuperAdmin: boolean;
	uuid: string;
	businessName: string;
	email: string;
	type: string;
	isNIMerchant: boolean;
	name: string;
	accessLevel: string;
	isUkMerchant: boolean;
	merchantType: MerchantType | null;
}
export interface Permissions {
	permissions: {
		id: number;
		name: string;
		display_name: string;
	}[];
	cuuid: string;
}

interface AuthStore {
	loginVault?: LoginVault;
	permissions?: Permissions;
	authenticate: (loginVault: LoginVault, permissions: Permissions) => void;
	updatePermissions: (permissions: Permissions) => void;
	logout: () => void;
	isLoggedIn?: boolean;
	setIsLoggedIn: (isLoggedIn: boolean) => void;
}

const loadUser = (): LoginVault | undefined => {
	const userLS = localStorage.getItem(KEYS_LOGIN);
	const userSS = sessionStorage.getItem(KEYS_LOGIN);
	const user = userLS || userSS;
	return user ? parseJson(user) : undefined;
};

const loadPermissions = (): Permissions | undefined => {
	const permissionsLS = localStorage.getItem(KEYS_PERMISSIONS);
	const permissionsSS = sessionStorage.getItem(KEYS_PERMISSIONS);
	const permissions = permissionsLS || permissionsSS;
	return permissions ? parseJson(permissions) : undefined;
};

const initialState: Partial<AuthStore> = {
	loginVault: loadUser(),
	permissions: loadPermissions(),
	isLoggedIn: false,
};

export const useAuthStore = create<AuthStore>()(
	devtools(
		(set, get) => ({
			...initialState,
			authenticate: (loginVault, permissions) => {
				set({ loginVault, permissions, isLoggedIn: true });
				const { keepSignIn } = loginVault;
				const storage = keepSignIn ? localStorage : sessionStorage;
				storage.setItem(KEYS_LOGIN, JSON.stringify(loginVault));
				storage.setItem(KEYS_PERMISSIONS, JSON.stringify(permissions));
			},
			updatePermissions: (permissions) => {
				set({ permissions });
				const { keepSignIn } = get().loginVault || ({} as LoginVault);
				const storage = keepSignIn ? localStorage : sessionStorage;
				storage.setItem(KEYS_PERMISSIONS, JSON.stringify(permissions));
			},
			logout: () => {
				onLogout();
				set({
					loginVault: undefined,
					permissions: undefined,
					isLoggedIn: false,
				});
			},
			setIsLoggedIn: (isLoggedIn: boolean) => {
				set({ isLoggedIn });
			},
		}),
		{ name: 'auth' }
	)
);

export const useLoginVault = () => {
	const loginVault = useAuthStore((state) => state.loginVault);
	return loginVault || ({} as LoginVault);
};

export const usePermissions = () => {
	const permissions = useAuthStore((state) => state.permissions);
	return permissions || ({} as Permissions);
};

export function onLogout() {
	localStorage.clear();
	localStorage.setItem('dev/allow', process.env.ALLOW_DEV_TOOLS || 'false');

	const cookies = new Cookies(null, { path: '/' });

	cookies.remove(TOKEN_COOKIE_NAME);
}
