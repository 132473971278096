import { ApiRequest } from "@zeal/zeal-lib";
import { useMutation } from "@app/QueryClient";
async function apiForgotPassword(payload) {
  return new ApiRequest("v3/auth/password/forget").addHeader("Accept", "application/json").addHeader("Content-Type", "application/json").POST(JSON.stringify(payload));
}
export function useForgetPassword(options) {
  const mutation = useMutation(
    (email) => apiForgotPassword({ email }),
    options
  );
  const { mutate } = mutation;
  return {
    ...mutation,
    doForgetPassword: mutate
  };
}
