import React, { useCallback } from 'react';
import { Button, Flex, Image, Icon, Label } from '@zeal/web-ui';
// @ts-ignore
import background from './background.svg';
import { useHistory } from 'react-router-dom';
import { BannerIconContainer } from '../StyledComponents';
import { useLoggedIn } from '@app/ACL/useLogin';
import { useTranslation } from 'react-i18next';

export default function AppMenuBanner() {
	const history = useHistory();

	const { t } = useTranslation('freemiumDashboard');

	const { merchantType } = useLoggedIn();

	const handleFeatureClick = useCallback(() => {
		history.push({
			pathname: '/freemium/upgrade-plan',
		});
	}, [history]);

	if (merchantType !== 'freemium') {
		return null;
	}

	return (
		<div className="relative text-white">
			<Image src={background} alt="Background" className="absolute" />
			<Flex gap="3" align="center" isColumn className="w-full relative p-4">
				<BannerIconContainer align="center" justify="center">
					<Icon iconName="crown" variant="regular" color="#F1C40F" />
				</BannerIconContainer>
				<Flex isColumn className="gap-[6px] w-56">
					<Label.Big300 textAlign="center" variant="on-primary">
						{t('upgrade_to_business')}
					</Label.Big300>
					<Label.Thin200 textAlign="center" variant="on-primary">
						{t('unlock_more_features')}
					</Label.Thin200>
				</Flex>
				<Button
					size="sm"
					variant="white"
					className="w-full justify-center h-8"
					onClick={handleFeatureClick}
				>
					<Label.Mid200 textAlign="center">✨ {t('upgrade_now')}</Label.Mid200>
				</Button>
			</Flex>
		</div>
	);
}
