import {
	Button,
	Flex,
	ControlledCheckbox,
	ControlledInput,
	Label,
} from '@zeal/web-ui';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { EMagicLinkType, TMagicLinkForm } from '../../types';
import {
	useMagicLinkRegister,
	useMagicLinkUpdatePassword,
} from '@app/data/MagicLink/useMagicLink';
import { useFormContext } from 'react-hook-form';
import { useMagicLinkFormContext } from './FormContext';
import { useHistory } from 'react-router-dom';

export default function CreatePassword() {
	const { t } = useTranslation('magicLink');
	const history = useHistory();

	const { watch, handleSubmit } = useFormContext<TMagicLinkForm>();
	const { goToNextStep, errors, setErrors } = useMagicLinkFormContext();

	const { doMagicLinkRegister, isLoading: isRegisterationLoading } =
		useMagicLinkRegister({
			onSuccess: (res: { is_email_verified: boolean }) => {
				if (res?.is_email_verified) {
					history.push({
						pathname: '/free-tier/login',
					});
				} else {
					goToNextStep();
				}
			},
			onError: (
				_: any,
				__: any,
				context: { parsedError: Record<string, any> }
			) => {
				if (context?.parsedError) {
					setErrors(context.parsedError.errors);
				}
			},
		});

	const { doMagicLinkUpdatePassword, isLoading: isUpdatePasswordLoading } =
		useMagicLinkUpdatePassword({
			onSuccess: () => {
				history.push({
					pathname: '/free-tier/login',
				});
			},
			onError: (
				_: any,
				__: any,
				context: { parsedError: Record<string, any> }
			) => {
				if (context?.parsedError) {
					setErrors(context.parsedError.errors);
				}
			},
		});

	const handleRegister = useCallback(
		(data: TMagicLinkForm) => {
			if (data.type === EMagicLinkType.VERIFICATION) {
				doMagicLinkRegister(data);
			} else {
				doMagicLinkUpdatePassword(data);
			}
		},
		[doMagicLinkRegister]
	);

	return (
		<>
			<Flex
				gap="6"
				isColumn
				styles={{
					width: '504px',
				}}
			>
				<ControlledInput<TMagicLinkForm>
					label={t('email')}
					name="email"
					type="email"
					className="w-full"
					style={{
						width: '504px',
					}}
					startIconName="envelope"
					placeholder={t('email_placeholder')}
					autoComplete="email"
					disabled={watch('type') === EMagicLinkType.REGISTRATION}
					isError={!!errors?.email}
					message={errors?.email}
				/>
				<ControlledInput<TMagicLinkForm>
					label={t('password')}
					name="password"
					type="password"
					className="w-full"
					style={{
						width: '504px',
					}}
					startIconName="lock"
					placeholder={t('password_placeholder')}
					autoComplete="new-password"
					isError={!!errors?.password}
					message={errors?.password}
				/>
				<ControlledInput<TMagicLinkForm>
					label={t('confirm_password')}
					name="password_confirmation"
					type="password"
					className="w-full"
					style={{
						width: '504px',
					}}
					startIconName="lock"
					placeholder={t('password_placeholder')}
					autoComplete="new-password"
					isError={!!errors?.password_confirmation}
					message={errors?.password_confirmation}
				/>
				<Flex align="center" gap="1">
					<ControlledCheckbox<TMagicLinkForm>
						name="accepted_terms"
						className="w-6 h-6"
						isError={!!errors?.accepted_terms}
						message={errors?.accepted_terms}
						// @ts-ignore
						label={
							<Flex align="center" gap="1">
								<Label.Thin300>{t('agree_to')}</Label.Thin300>
								<a
									href="https://zealpay.app/en/term-and-conditions/#introduction"
									target="_blank"
									rel="noopener noreferrer"
								>
									<Label.Thin300 variant="interactive">
										{t('zeals_terms_and_conditions')}
									</Label.Thin300>
								</a>
							</Flex>
						}
					/>
				</Flex>
			</Flex>
			<Button
				variant="primary"
				size="md"
				className="w-full text-center justify-center"
				isLoading={isRegisterationLoading || isUpdatePasswordLoading}
				style={{
					width: '504px',
				}}
				onClick={handleSubmit(handleRegister)}
			>
				{t('sign_up')}
			</Button>
		</>
	);
}
