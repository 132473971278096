import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Button,
	DropdownMenu,
	Flex,
	Icon,
	IconColors,
	IconNames,
	Label,
} from '@zeal/web-ui';
import LanguageButton from './LanguageButton';
import { HorizontalDivider } from './StyledComponents';
import IconButton from './IconButton';
import { useHistory } from 'react-router-dom';
import { useLoggedIn, useLogout } from '../../ACL/useLogin';

import tw from 'twin.macro';

import { useTranslation } from 'react-i18next';
import { getFireBaseToken } from '../../../firebase';
import { withTimeout } from '../utils/withTimeout';
import { useFetchLocals } from '@app/hooks';

declare const window: any;

export default function DashboardDropdown() {
	const [isClosed, setIsClosed] = useState(true);
	const handleOpenChange = useCallback(
		(isClosed: boolean) => setIsClosed(!isClosed),
		[]
	);

	const { i18n, t } = useTranslation('navbar');

	const history = useHistory();

	const { doLogout, isLoading: isLogoutLoading } = useLogout();
	const { merchantType } = useLoggedIn();

	const openHelp = useCallback(() => {
		setIsClosed(true);
		window.FreshworksWidget('open');
		window.FreshworksWidget('hide', 'launcher');
	}, []);

	const settingsRoute = useCallback(() => {
		setIsClosed(true);
		history.push('/settings/profile');
	}, [history]);

	const { data } = useFetchLocals();

	const languageOptions = useMemo(() => data || [], [data]);

	const iconButtonOptions = useMemo(
		() => [
			{
				id: 'help',
				label: t('help'),
				icon: 'circleQuestion' as IconNames,
				onClick: openHelp,
				color: 'darkGrey' as IconColors,
			},
			{
				id: 'settings',
				label: t('settings'),
				icon: 'cog' as IconNames,
				onClick: settingsRoute,
				color: 'darkGrey' as IconColors,
			},
		],
		[t, merchantType, openHelp, settingsRoute]
	);

	const generateLanguageButtons = languageOptions.map((i) => (
		<LanguageButton
			key={i.id}
			value={i.code}
			label={i.name}
			onClick={() => setIsClosed(true)}
		/>
	));

	const generateIconButtons = iconButtonOptions.map((i) => (
		<IconButton
			key={i.id}
			icon={i.icon}
			label={i.label}
			onClickFunc={i.onClick}
			color={i.color}
		/>
	));

	const logoutUser = () => {
		const timedOutFireBaseToken = withTimeout(5000, getFireBaseToken());

		timedOutFireBaseToken
			.then((token) => {
				if (!!token) {
					doLogout({ fcm_token: token });
				} else {
					doLogout({});
				}
			})
			.catch(() => {
				doLogout({});
			});
	};

	return (
		<DropdownMenu onOpenChange={handleOpenChange} open={!isClosed}>
			<DropdownMenu.Trigger className="group">
				<Button
					variant={!isClosed ? 'primary' : 'transparent'}
					rounded="full"
					CSS={tw`p-[16px] w-10 h-10 justify-center content-center group-data-[state=closed]:hover:bg-[#F1F2F3]`}
					size="lg"
				>
					<Icon
						iconName="chevronDown"
						variant="regular"
						colorVariant={!isClosed ? 'white' : 'darkGrey'}
					/>
				</Button>
			</DropdownMenu.Trigger>
			<DropdownMenu.Content style={{ width: '180px', padding: '8px' }}>
				<Flex isColumn gap="sm">
					<Label size="sm">{t('language')}</Label>
					{generateLanguageButtons}
					<HorizontalDivider />
					{generateIconButtons}
					<IconButton
						icon="arrowRightFromBracket"
						label={t('logout')}
						onClickFunc={logoutUser}
						color="danger"
						isLoading={isLogoutLoading}
					/>
				</Flex>
			</DropdownMenu.Content>
		</DropdownMenu>
	);
}
