import React from 'react';
import DefaultNavBarAvatar from './DefaultNavBarAvatar';
import { useLoggedIn } from '@app/ACL/useLogin';
import BusinessNavBarAvatar from './BusinessNavBarAvatar';

export default function NavBarAvatar() {
    const { merchantType } = useLoggedIn();

    if (['premium', 'freemium', 'cardlink'].includes(merchantType)) {
        return <BusinessNavBarAvatar />
    }

    return <DefaultNavBarAvatar />
}
