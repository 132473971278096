import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { Button, ControlledInput, Flex, Icon, Label } from "@zeal/web-ui";
import { Card } from "../../Login/Login";
import { useForgetPassword } from "../data/useForgotPassword";
import { sharedSchema } from "@zeal/zeal-lib";
export default function ForgetPasswordCard({ setEmailSent }) {
  const { t } = useTranslation("login");
  const schema = yup.object({
    email: sharedSchema.emailSchema({})
  });
  const formMethods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  });
  const { handleSubmit } = formMethods;
  const { isError, isLoading, doForgetPassword } = useForgetPassword({
    onSuccess: () => {
      setEmailSent(true);
      return null;
    },
    disableToaster: true
  });
  const history = useHistory();
  const handleCancel = () => history.push("/");
  const forgetPasswordSubtitle = /* @__PURE__ */ React.createElement(React.Fragment, null, t("enter_email"), t("link"));
  const handleSubmitEmail = ({ email }) => {
    doForgetPassword(email);
  };
  return /* @__PURE__ */ React.createElement("form", { onSubmit: handleSubmit(handleSubmitEmail) }, /* @__PURE__ */ React.createElement(Card, { className: "max-w-xl p-8 mx-auto border-none shadow " }, /* @__PURE__ */ React.createElement(Flex, { justify: "center" }, /* @__PURE__ */ React.createElement(Label, null, /* @__PURE__ */ React.createElement(
    Icon,
    {
      iconName: "lock",
      size: "7x",
      variant: "regular",
      colorVariant: "lightGray"
    }
  ))), /* @__PURE__ */ React.createElement(Flex, { justify: "center", className: "py-3" }, /* @__PURE__ */ React.createElement(Flex, { className: "pt-2 m-1 text-center", isColumn: true }, /* @__PURE__ */ React.createElement(Label, { size: "3xl", weight: "semiBold" }, t("forget_password")), /* @__PURE__ */ React.createElement(Label, { variant: "lightGray", size: "md" }, forgetPasswordSubtitle))), /* @__PURE__ */ React.createElement(FormProvider, { ...formMethods }, /* @__PURE__ */ React.createElement(
    ControlledInput,
    {
      name: "email",
      type: "email",
      placeholder: t("email"),
      inputName: "email"
    }
  )), isError && /* @__PURE__ */ React.createElement(Label, { variant: "danger" }, "Email not verified or does not exist!"), /* @__PURE__ */ React.createElement(Flex, { isColumn: true, gap: "sm", className: "pt-4" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      variant: "primary",
      fullWidth: true,
      size: "md",
      disabled: isLoading,
      isLoading,
      type: "submit"
    },
    t("continue_btn")
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      variant: "default",
      fullWidth: true,
      size: "md",
      disabled: isLoading,
      onClick: handleCancel
    },
    t("cancel_btn")
  ))));
}
