import React from 'react';
import toast from 'react-hot-toast';
import { Toaster, ToasterProps } from '@zeal/web-ui';

export default function openToaster(props: ToasterProps) {
	return toast.custom(
		(t) => {
			const handleClick = () => {
				props.onClick?.();
				toast.dismiss(t?.id);
			};

			return <Toaster {...props} onClick={handleClick} />;
		},
		{
			duration: props.duration || 4000,
		}
	);
}
