import { TPathOptions, RouteFlags } from '../App/types/types';

const terminalManagementPathOptions: TPathOptions = {
	apiKey: 'terminal_management',
	path: '/terminal-management',
	label: 'terminal_management',
	iconName: 'mobileRetro',
	menuOrder: 1,
	ownApp: false,
	subMenu: [
		{
			featureApiKey: 'terminal_management:branches_and_terminals',
			label: 'business',
			path: '/terminal-management/branches',
		},
	],
	excludedFor: [],
};

export default terminalManagementPathOptions;
