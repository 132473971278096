import React, { useMemo } from 'react';
import DashboardLogoLayout from './DashboardLogoLayout';
import { Image } from '@zeal/web-ui';
import { useLoggedIn } from '@app/ACL/useLogin';
import cardlink from './logos/cardlink-logo.svg';
import free from './logos/free-logo.svg';
import business from './logos/business-logo.svg';

const BusinessDashboardLogo = () => {
	const { merchantType } = useLoggedIn();

	const tierImage = useMemo(() => {
		switch (merchantType) {
			case 'freemium':
				return <Image src={free} alt="Free Tier" />;
			case 'cardlink':
				return <Image src={cardlink} alt="Cardlink Tier" />;
			case 'premium':
				return <Image src={business} alt="Business Tier" />;
			default:
				return <></>;
		}
	}, [merchantType]);

	return <DashboardLogoLayout Tierlogo={tierImage} currentAppName="Zeal" />;
};

export default BusinessDashboardLogo;
