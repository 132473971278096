import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Flex, Label, Icon, Button } from "@zeal/web-ui";
import { Card } from "../../Login/Login";
export default function EmailSentCard(props) {
  const { t } = useTranslation("login");
  const history = useHistory();
  const emailSubtitle = /* @__PURE__ */ React.createElement(React.Fragment, null, t("question"), /* @__PURE__ */ React.createElement("br", null), t("email_us"));
  const sentEmailText = /* @__PURE__ */ React.createElement(React.Fragment, null, t("email_msg"), /* @__PURE__ */ React.createElement("br", null), t("ur_password"));
  const backToLogin = useCallback(() => {
    history.push("/");
  }, [history]);
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Card, { className: "max-w-xl p-8 mx-auto border-none shadow " }, /* @__PURE__ */ React.createElement(Flex, { className: "pt-2 m-2", isColumn: true }, /* @__PURE__ */ React.createElement(Flex, { justify: "center", align: "center" }, /* @__PURE__ */ React.createElement(Label, null, /* @__PURE__ */ React.createElement(
    Icon,
    {
      iconName: "envelope",
      size: "6x",
      variant: "thin",
      colorVariant: "lightGray"
    }
  )))), /* @__PURE__ */ React.createElement(Flex, { justify: "center", align: "center" }, /* @__PURE__ */ React.createElement(Flex, { gap: "sm", className: "pt-2 m-2 ", justify: "center", isColumn: true }, /* @__PURE__ */ React.createElement(Label, { size: "4xl", weight: "semiBold" }, t("check_email")), /* @__PURE__ */ React.createElement(Label, { variant: "lightGray", size: "sm" }, sentEmailText, " "))), /* @__PURE__ */ React.createElement("hr", null), /* @__PURE__ */ React.createElement(Flex, { justify: "center", align: "center" }, /* @__PURE__ */ React.createElement(Flex, { isColumn: true, gap: "xs" }, /* @__PURE__ */ React.createElement(Label, { size: "2xl", weight: "semiBold", className: "my-2" }, emailSubtitle), /* @__PURE__ */ React.createElement(Label, { variant: "primary", size: "md" }, "LOL@myzeal.app"))), /* @__PURE__ */ React.createElement(
    Button,
    {
      onClick: backToLogin,
      variant: "primary",
      fullWidth: true,
      size: "md",
      className: "mt-4"
    },
    t("back_to_login")
  )));
}
