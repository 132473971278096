import React, { useEffect, useState } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useTranslation } from 'react-i18next';

import {
	QueryClient,
	QueryClientProvider,
	useMutation as useLibMutation,
	useQuery as useLibQuery,
} from '@tanstack/react-query';

import type { MutationFunction } from '@tanstack/react-query';

import { useOwnAppProvider } from '../AppContainer/useOwnApp';
import type { MutationOptions, TApiError } from './types';

import openToaster from '../Toastr/openToastr';
import { useQueryKey } from '../data';
import { useAppState } from '../store/appStore';
import { set } from 'lodash-es';

export const queryClient = new QueryClient();

export default function QueryProvider({
	children,
}: {
	children: React.ReactNode;
}) {
	return (
		<QueryClientProvider client={queryClient}>
			{children}
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
}

const isQueryEnabled = ({ appName }: { appName?: string | null }) =>
	appName ? true : false;

export function useAppQuery<TData = any>(options: any) {
	const { currentAppName: appName, isCustomAppActive: isAppOwn } =
		useAppState();
	const queryKey = useQueryKey({ queryKey: options.queryKey });

	const [queryOptions, setQueryOptions] = useState({
		...options,
		enabled: false,
	});

	const [isRouteOwnApp] = useOwnAppProvider();

	const manageOptions = () => {
		options.retry = options.retry || false;
		// TODO: refactor other conditions to be inside isQueryEnabled
		options.enabled =
			(options.enabled ?? isQueryEnabled({ appName })) &&
			((isRouteOwnApp && isAppOwn) ||
				!isRouteOwnApp ||
				options?.skipOwnApp === true);

		setQueryOptions({ ...options });
	};

	React.useEffect(() => {
		manageOptions();
	}, [isAppOwn, isRouteOwnApp, appName]);

	const q = useLibQuery({
		...queryOptions,
		queryKey,
		enabled: options.enabled ?? isQueryEnabled({ appName }),
	});

	return {
		...q,
		data: q.data as TData,
		isLoading: q.isLoading,
	};
}

export async function parseError<T>(error: any) {
	try {
		const clonedError = error.response.clone();
		if (!clonedError.bodyUsed) {
			const err: TApiError<T> = await clonedError.json();
			const parsedError = {
				message: err?.message,
				errors: {},
			};

			Object.entries(err?.errors || {}).forEach(([key, value]) => {
				set(parsedError.errors, key, value);
			});

			return parsedError as TApiError<T>;
		}
	} catch (err) {
		return;
	}
}

export function useMutation<
	TData = any,
	TError = unknown,
	TVariables = unknown,
	TContext extends { parsedError?: TApiError<TError> } | object = {
		parsedError?: TApiError<TError>;
	}
>(
	mutationFn: MutationFunction<TData, TVariables>,
	options: MutationOptions<TData, TError, TVariables, TContext>
) {
	const [parsedError, setParsedError] = useState<TApiError<TError>>();

	const { operation, moduleName, disableToaster } = options;

	const { t } = useTranslation('common');
	const mutation = useLibMutation<TData, TError, TVariables, TContext>({
		...options,
		mutationFn,
		onMutate: (variables) => {
			setParsedError(undefined);
			return options?.onMutate?.(variables);
		},
		onSuccess: (...args) => {
			const variables = args[1];

			if (!disableToaster) {
				const successMessage =
					typeof options?.successMessage === 'function'
						? options?.successMessage(variables)
						: options?.successMessage;

				const op =
					typeof operation === 'function' ? operation(variables) : operation;

				openToaster({
					message: successMessage ?? t(`${op}_success`, { moduleName }),
				});
			}

			options.onSuccess?.(...args);
		},
		onError: async (error, variables, context: any) => {
			const parsedError = await parseError<TData>(error);

			if (!disableToaster) {
				const failMessage =
					typeof options?.failMessage === 'function'
						? options?.failMessage(variables)
						: options?.failMessage;

				const op =
					typeof operation === 'function' ? operation(variables) : operation;

				openToaster({
					message:
						failMessage ||
						parsedError?.message ||
						t(`${op}_fail`, { moduleName }),
					isError: true,
				});
			}

			options.onError?.(error, variables, { ...context, parsedError });
		},
	});

	useEffect(() => {
		if (mutation?.error)
			parseError<TError>(mutation?.error)?.then(setParsedError);
	}, [mutation?.error]);

	return {
		...mutation,
		parsedError,
		isLoading: mutation.isPending,
	};
}
