import React, { useMemo } from 'react';
import tw from 'twin.macro';
import { Link } from 'react-router-dom';
import { Box, Flex, Image, Label, ZealSvg, Tooltip } from '@zeal/web-ui';
import {  DashboardWrapper, VerticalDivider } from '../StyledComponents';


type DashboardLogoProps = {
    currentAppName: string;
    appLogo?: React.ReactNode;
    Tierlogo?: React.ReactNode;
}
export default function DashboardLogoLayout(props: DashboardLogoProps) {
    const { currentAppName, appLogo, Tierlogo } = props;

    const zealImage = useMemo(
        () => <ImageWrapper>{ZealSvg.zealIcon}</ImageWrapper>,
        []
    );
	
	return (
		<DashboardWrapper align='center' justify='between' gap='sm'>
			<Tooltip tooltipId="custom-tooltip" title={currentAppName as string}>
				<Flex align="center" gap="lg" justify="between">
					<Flex gap="3" align="center">
						<Link to="/">{appLogo ?? zealImage}</Link>
						<Label
							weight="semiBold"
							size="xl"
							CSS={tw`max-w-[145px] whitespace-nowrap text-ellipsis overflow-hidden`}
						>
							{currentAppName}
						</Label>
					</Flex>
					{Tierlogo}
				</Flex>
			</Tooltip>
			<VerticalDivider />
		</DashboardWrapper>
	);
}


export function ImageWrapper({ children }: { children: React.ReactNode }) {
	return (
		<Box
			background="gray-100"
			rounded="lg"
			CSS={tw`flex items-center justify-center w-12 h-12`}
		>
			{children}
		</Box>
	);
}
