import React, { useMemo, useCallback, memo } from 'react';
import tw from 'twin.macro';

import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

import { Icon, Button, Flex, Tooltip } from '@zeal/web-ui';

import { useAccountInfo } from '../../BusinessAccount/useBusinessInfo';
import { useAppState } from '../../store/appStore';
import { useModalStore } from '@app/store/modalStore';

function OwnAppSwitcher() {
	const { updateApp, isLoading } = useAccountInfo();
	const { isOpen: isModalOpen } = useModalStore((state) => state);

	const { customAppId, customAppName, isCustomAppActive, hasCustomApp } =
		useAppState();

	const { t } = useTranslation('common');

	const appsOptions = useMemo(
		() => [
			{
				id: '1',
				isZeal: true,
				value: 'zeal',
				label: 'Zeal Board Analytics',
			},
			{
				id: customAppId,
				isOwn: true,
				value: customAppName,
				label: `${customAppName} App`,
			},
		],
		[customAppId, customAppName]
	);

	const handleSwitch = useCallback(() => {
		if (isCustomAppActive) {
			updateApp({
				appId: (appsOptions[0].id || '')?.toString(),
				appName: appsOptions[0].value || '',
			});
		} else {
			updateApp({
				appId: (appsOptions[1].id || '')?.toString(),
				appName: appsOptions[1].value || '',
			});
		}
	}, [get(appsOptions, `0.value`), get(appsOptions, `0.id`), updateApp]);

	if (!hasCustomApp) {
		return null;
	}

	return (
		<Flex gap="md" align="center">
			<AnimatePresence initial={false}>
				<Tooltip
					title={t('own_app_tooltip', {
						appName: !isCustomAppActive ? customAppName : 'Zeal',
					})}
					tooltipId="appSwitcher"
				>
					<motion.div
						animate={isCustomAppActive ? 'custom' : 'zeal'}
						transition={{ duration: 0.4 }}
						variants={{
							zeal: {
								transform: 'rotateY(0deg)',
							},
							custom: {
								transform: 'rotateY(180deg)',
							},
						}}
					>
						<Button
							id="appSwitcher"
							disabled={isLoading || isModalOpen}
							onClick={handleSwitch}
							variant={isCustomAppActive ? 'primary' : 'transparent'}
							size="lg"
							rounded="full"
							CSS={[
								tw`p-[16px] w-12 h-12 justify-center content-center`,
								!isCustomAppActive && tw`hover:bg-[#F1F2F3]`,
							]}
						>
							<Icon
								iconName="arrowRightArrowLeft"
								variant="regular"
								colorVariant={isCustomAppActive ? 'white' : 'darkGrey'}
							/>
						</Button>
					</motion.div>
				</Tooltip>
			</AnimatePresence>
		</Flex>
	);
}

export default memo(OwnAppSwitcher);
