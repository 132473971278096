import React, { useMemo } from 'react'
import { LetterWrapper } from '../StyledComponents';
import { Image, Label } from '@zeal/web-ui';
import DashboardLogoLayout, { ImageWrapper } from './DashboardLogoLayout';
import { useAppState } from '@app/store/appStore';
import { useBusinessInfo } from '@app/BusinessAccount/useBusinessInfo';
import tw from 'twin.macro';

const CustomAppDashboardLogo = () => {
    const {  customAppName } = useAppState();
    const { data } = useBusinessInfo();
    const photoLink = data?.business?.photo;

    const customAppLetter = (
        <LetterWrapper style={{ borderRadius: '8px' }}>
            <Label size="xl" CSS={tw`text-[#FB7720]`}>
                {(customAppName || '?')?.charAt(0)}
            </Label>
        </LetterWrapper>
    );

    const customAppImage = useMemo(
        () => (
            <ImageWrapper>
                <Image rounded="md" width={26} src={photoLink} />
            </ImageWrapper>
        ),
        [photoLink]
    );

;

    const appLogo = (photoLink != null)
        ? customAppImage
        : customAppLetter


    return (
        <DashboardLogoLayout
            currentAppName={customAppName!}
            appLogo={appLogo}
        />
    )
}

export default CustomAppDashboardLogo