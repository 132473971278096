import React from 'react'
import NavBarAvatarLayout from './NavBarAvatarLayout'
import { useAvatarInfo } from './useAvatarInfo';
import { useLoggedIn } from '@app/ACL/useLogin';

const DefaultNavBarAvatar = () => {
    const { letter,displayAccessLevel,displayName} = useAvatarInfo()
    
    return (
        <NavBarAvatarLayout
            letter={letter}
            displayAccessLevel={displayAccessLevel}
            displayName={displayName}
            showCredits={true} />
    )
}

export default DefaultNavBarAvatar