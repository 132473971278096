import React, { useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Flex,
  Label,
  Padding,
  ZealSvg,
  Input,
  Button,
  InlineLoading,
  CardBare,
  palette
} from "@zeal/web-ui";
import { useLoggedIn, useLogin, useLogout } from "../ACL/useLogin";
import openToaster from "../Toastr/openToastr";
import { get } from "lodash-es";
import LanguageSelectorWrapper from "../AppContainer/components/LoginLanguageWrapper";
import { useQueryString } from "@zeal/zeal-lib";
import { useLoginWithAdminToken } from "@app/ACL/useAdminLogin";
import { useFetchTranslation } from "@app/hooks";
export default function Login(props) {
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState();
  const [errorToasterId, setErrorToasterId] = React.useState(null);
  const { isTranslationsLoading } = useFetchTranslation("login");
  const { t } = useTranslation("login");
  const history = useHistory();
  const [query] = useQueryString();
  const adminToken = query?.login_token;
  const { isLoggedIn } = useLoggedIn();
  const { doLogout } = useLogout();
  const onLoginSuccess = useCallback(() => {
    const { history: history2, location } = props;
    if (location?.state?.shouldGoBack) {
      history2.push(get(location, "state.fromLocation.pathname", "/"));
      return null;
    } else {
      setTimeout(() => {
        history2.push("/");
      }, 10);
      return null;
    }
  }, [props]);
  const { doLogin: doLoginWithAdminToken } = useLoginWithAdminToken({
    onSuccess: () => {
      onLoginSuccess();
      openToaster({
        message: t("admin_login_success_title"),
        isError: false
      });
    },
    onError: (err) => {
      openToaster({
        message: err?.error?.message || t("admin_login_failed_title"),
        isError: true
      });
    }
  });
  useEffect(() => {
    if (isLoggedIn && adminToken) {
      doLogout(
        {},
        {
          onSuccess: () => {
            doLoginWithAdminToken({ token: adminToken });
          },
          onError: () => {
            openToaster({
              message: t("admin_logout_failed"),
              isError: true
            });
          }
        }
      );
    } else if (adminToken) {
      doLoginWithAdminToken({ token: adminToken });
    } else if (isLoggedIn) {
      history.push("/");
    }
  }, []);
  const { doLogin, isLoading } = useLogin({
    onSuccess: onLoginSuccess,
    onError: ({ toastId }) => {
      setErrorToasterId(toastId);
    }
  });
  const handleLoginSubmit = React.useCallback(() => {
    if (email && password) {
      doLogin({ email, password, keepSignIn: true });
      if (errorToasterId) {
        toast.dismiss(errorToasterId);
      }
    } else {
    }
  }, [email, password, doLogin, errorToasterId]);
  const handleEnterKeyPress = React.useCallback(
    (event) => {
      if (event.key === "Enter") {
        handleLoginSubmit();
      }
    },
    [handleLoginSubmit]
  );
  const loginButtonContent = isLoading ? /* @__PURE__ */ React.createElement(InlineLoading, { label: " ", className: "!py-0" }) : /* @__PURE__ */ React.createElement(Label, { variant: "white" }, t("login"));
  const openForgotPassword = useCallback(() => {
    history.push("/forgot-password");
  }, [history]);
  const onEmailChange = useCallback((evt) => setEmail(evt.target.value), []);
  const onPasswordChange = useCallback(
    (evt) => setPassword(evt.target.value),
    []
  );
  const loginForm = /* @__PURE__ */ React.createElement(
    Padding,
    {
      padding: {
        horizontal: "sm"
      }
    },
    /* @__PURE__ */ React.createElement(Card, { className: "mx-auto p-12 shadow border-none " }, /* @__PURE__ */ React.createElement(TextCenter, null, /* @__PURE__ */ React.createElement(Padding, { padding: { vertical: "md" } }, ZealSvg.zealType), /* @__PURE__ */ React.createElement(Label.Mid700, null, /* @__PURE__ */ React.createElement(Padding, { padding: { vertical: "sm" } }, t("login_to_zeal")), " "), /* @__PURE__ */ React.createElement(Label.Mid400, { variant: "subdued" }, /* @__PURE__ */ React.createElement(Padding, { padding: { vertical: "sm" } }, t("connecting_with_zeal")), " ")), /* @__PURE__ */ React.createElement(Flex, { isColumn: true, gap: "sm" }, /* @__PURE__ */ React.createElement(
      Input,
      {
        type: "email",
        name: "email",
        placeholder: t("email"),
        onChange: onEmailChange,
        onKeyPress: handleEnterKeyPress
      }
    ), /* @__PURE__ */ React.createElement(
      Input,
      {
        type: "password",
        name: "password",
        placeholder: t("password"),
        onChange: onPasswordChange,
        onKeyPress: handleEnterKeyPress
      }
    )), /* @__PURE__ */ React.createElement(Flex, { justify: "end" }, /* @__PURE__ */ React.createElement(Padding, { padding: { vertical: "md" } }, /* @__PURE__ */ React.createElement(
      Button,
      {
        variant: "transparent",
        className: "!m-0 !p-0",
        onClick: openForgotPassword
      },
      /* @__PURE__ */ React.createElement(Padding, { padding: { vertical: "sm" } }, /* @__PURE__ */ React.createElement(Label, { variant: "secondary", size: "sm" }, t("forgot_password")))
    ))), /* @__PURE__ */ React.createElement(Flex, { isColumn: true, gap: "6" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        variant: "primary",
        fullWidth: true,
        size: "md",
        disabled: isLoading,
        onClick: handleLoginSubmit
      },
      loginButtonContent
    ), /* @__PURE__ */ React.createElement(Label, { textAlign: "center" }, /* @__PURE__ */ React.createElement(Label.Mid200, { variant: "subdued", ti: true }, t("agree_to_our_terms_and_conditions")), " ", /* @__PURE__ */ React.createElement(
      Label.Mid200,
      {
        variant: "subdued",
        style: {
          color: palette.text.interactive
        }
      },
      /* @__PURE__ */ React.createElement(
        "a",
        {
          href: "https://myzeal.app/en/term-and-conditions/#introduction",
          target: "_blank"
        },
        t("zeal_terms")
      )
    ))))
  );
  if (isTranslationsLoading) {
    return /* @__PURE__ */ React.createElement(InlineLoading, { label: " " });
  }
  return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(Flex, { isColumn: true, gap: "lg" }, loginForm, /* @__PURE__ */ React.createElement(LanguageSelectorWrapper, { isLoginPage: !isLoggedIn })));
}
export const TextCenter = styled.div`
	width: 100%;
	display: grid;
	text-align: center;
	place-items: center;
	place-content: center;
`;
export const Container = styled.div`
	display: grid;
	place-items: center;
	place-content: center;
	width: 100vw;
	height: 100vh;
`;
export const Card = styled(CardBare)`
	min-width: 400px;
	max-width: 585px;
	width: 100%;
	cursor: default;
`;
